import { useContext, useState } from "react";
import {
  IconButton,
  ListItem,
  List,
  Drawer,
  styled,
  Box,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../FamilyGiftWebApp/Pages/HouseHolds/HouseDetailPage";
import AvatarPicture from "../ReusableComponents/AvatarPicture";
import { AuthContext } from "../../api/AuthProvider";

const DrawerComponent = (props: { isUserConnected: Boolean | undefined }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const MyButtonStyle = styled(NavLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "32px",
    fontSize: 16,
    margin: "auto",
    padding: "16px 24px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
      fontSize: 16,
    },
  }));

  const MyLoginButtonStyle = styled(NavLink)(({ theme }) => ({
    color: "white",
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "32px",
    fontSize: 16,
    margin: "auto",
    padding: "16px 24px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
      fontSize: 16,
    },
  }));

  var auth = useContext(AuthContext);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <List>
          <ListItem>
            <img
              src="/assets/family_gift_logo_simple.svg"
              alt="Family Gift Logo"
              onClick={() => {
                navigate("/");
                setOpenDrawer(!openDrawer);
              }}
              style={{
                height: "100px",
                width: "100px",
                padding: "24px",
                margin: "auto",
              }}
            />
          </ListItem>
          <ListItem>
            { auth?.userConnected && !auth?.userReconnected ? "Reconnexion" : auth?.userReconnected ? (
              <PrimaryButton
                sx={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                onClick={() => navigate("/app/myList")}
                endIcon={
                  <Box width={"35px"} height={"35px"}>
                    <AvatarPicture
                      alt={auth?.user?.username as string}
                      url={auth?.user?.photoUrl}
                    />
                  </Box>
                }
              >
                <Typography fontSize={18} fontWeight={"bold"}>
                  {t("myApp")}
                </Typography>
              </PrimaryButton>
            ) : (
              <MyLoginButtonStyle to="/login"> {t("login")}</MyLoginButtonStyle>
            )}
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/">{t("home")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/download">{t("theApplication")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/faq">{t("howItWorksTitle")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/contact">{t("contact")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/about">{t("about")}</MyButtonStyle>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        style={{ color: "white" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <Menu>Menu</Menu>
      </IconButton>
    </>
  );
};

export default DrawerComponent;
