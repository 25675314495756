import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../api/AuthProvider";
import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import {
  OutlinedButton,
  PrimaryErrorButton,
} from "../HouseHolds/HouseDetailPage";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import EditProfileDialog from "./EditProfileDialog";
import {
  UserLightWithoutWishlist,
  daysBeforeNextBirthday,
} from "../../../../models/User";
import {
  CakeOutlined,
  DeleteForeverOutlined,
  Logout,
} from "@mui/icons-material";
import EditChildDialog from "./EditChildDialog";
import CreateChildDialog from "./CreateChildDialog";
import { apiClient, apiFcmTokenClient } from "../../../../api/api";
import { getFcmToken } from "../../../../localStorageUtils";
import { enqueueSnackbar } from "notistack";
import MyWaitingDialog, {
  MyPaperPropsDialog,
} from "../../../ReusableComponents/MyWaitingDialog";
import { useTranslation } from "react-i18next";

function MyProfilePage() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  document.title = t("myProfile");
  return (
    <Box
      sx={{
        maxWidth: "1400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Box>
        <Typography
          textAlign={"center"}
          paddingTop={2}
          color={"primary"}
          fontSize={24}
          fontWeight={"bold"}
        >
          {t("myProfile")}
        </Typography>
      </Box>
      {isMobile ? (
        <Box>
          <MyInformations />
          <MyChilds />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <MyInformations />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <MyChilds />
          </Box>
        </Box>
      )}
      <GestionUser />
    </Box>
  );
}

function MyInformations() {
  const auth = useContext(AuthContext);
  const user = auth?.user;
  let time = null;
  const { t, i18n } = useTranslation();

  if (user?.birthdayDate ?? "".length > 0) {
    time = new Intl.DateTimeFormat(i18n.language, {
      month: "long",
      day: "2-digit",
      year: "numeric",
    }).format(new Date(user?.birthdayDate ?? ""));
  }

  var [editUserDialog, setEditUserDialog] = useState(false);

  useEffect(() => {
    const onHashChange = () => {
      setEditUserDialog(window.location.hash === "#edit_profile");
    };
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  function handleClickOpenEditProfile() {
    window.location.hash = "#edit_profile";
  }

  function handleClose() {
    window.history.back();
  }

  return user ? (
    <Box
      sx={{
        background:
          "linear-gradient(15deg, #E1D5FF 5%, #ffffff 50%,#FFDFEFFF 100%)",
        marginTop: "16px",
        border: "solid 2px",
        borderColor: "primary.light",
        borderRadius: "16px",
        boxSizing: "border-box",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <EditProfileDialog
        user={user}
        open={editUserDialog}
        onClose={(newUser) => {
          if (newUser) {
            auth.setUser(newUser);
          }
          handleClose();
        }}
      />
      <Box sx={{ width: "40%", aspectRatio: "1/1" }}>
        <AvatarPicture url={user.photoUrl} alt={user.username} />
      </Box>
      <Typography
        textAlign={"center"}
        paddingTop={2}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("yourName")}
      </Typography>
      <Typography>{user.username}</Typography>
      {time ? (
        <>
          <Typography
            textAlign={"center"}
            paddingTop={2}
            color={"primary"}
            fontSize={20}
            fontWeight={"bold"}
          >
            {t("yourBirthdate")}
          </Typography>
          <Typography>{time}</Typography>
        </>
      ) : (
        <></>
      )}

      <OutlinedButton
        sx={{ maxWidth: "250px" }}
        onClick={handleClickOpenEditProfile}
      >
        {t("editMyProfile")}
      </OutlinedButton>
    </Box>
  ) : (
    <p>error</p>
  );
}

function MyChilds() {
  var auth = useContext(AuthContext);
  var user = auth?.user;
  const { t } = useTranslation();

  var [childrenToEdit, setChildrenToEdit] =
    useState<UserLightWithoutWishlist | null>(null);
  var [dialogEdit, setDialogEdit] = useState(false);
  var [createChildren, setCreateChildren] = useState(false);

  useEffect(() => {
    const onHashChange = () => {
      setDialogEdit(window.location.hash.includes("edit_children_"));
      setCreateChildren(window.location.hash === "#create_children");
    };
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  function handleClickOpenEditProfile(children: UserLightWithoutWishlist) {
    setChildrenToEdit(children);
    window.location.hash = "#edit_children_" + children.id;
  }

  function handleClickOpenCreateChildre() {
    window.location.hash = "#create_children";
  }

  function handleClose() {
    window.history.back();
    setChildrenToEdit(null);
  }

  return (
    <Box
      sx={{
        background:
          "linear-gradient(-15deg, #E1D5FF 5%, #ffffff 50%,#FFDFEFFF 100%)",
        marginTop: "16px",
        border: "solid 2px",
        borderColor: "primary.light",
        borderRadius: "16px",
        boxSizing: "border-box",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <CreateChildDialog
        open={createChildren}
        onClose={(newUser) => {
          if (newUser) {
            auth?.setUser(newUser);
          }
          handleClose();
        }}
      />
      {childrenToEdit ? (
        <EditChildDialog
          child={childrenToEdit}
          open={dialogEdit}
          onClose={(newUser) => {
            if (newUser) {
              auth?.setUser(newUser);
            }
            handleClose();
          }}
        />
      ) : (
        <></>
      )}
      <Typography fontWeight={"bold"} paddingBottom={"16px"} color={"primary"}>
        {t("myChildren")}
      </Typography>
      {auth?.user?.children.length == 0 ? (
        <Box
          marginLeft={0}
          marginRight={0}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography paddingBottom={"16px"} textAlign={"center"}>
            {t("dontHaveChildrenYet")}
          </Typography>
          <Typography paddingBottom={"16px"} textAlign={"center"}>
            {t("dontHaveChildExplain")}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography textAlign={"center"}>{t("explainChildren")}</Typography>
          <Grid container alignItems="center" justifyContent="center">
            {auth?.user?.children?.sort((a,b) => {
                        if (a.username < b.username) {
                          return -1;
                        }
                        if (a.username > b.username) {
                          return 1;
                        }
                        return 0;
                      }).map((item, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={4}
                  lg={3}
                  xl={3}
                  padding={1}
                  key={item.id}
                >
                  <ThisUserItem
                    user={item}
                    onClick={() => {
                      handleClickOpenEditProfile(item);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
      <OutlinedButton
        sx={{ maxWidth: "400px" }}
        onClick={handleClickOpenCreateChildre}
      >
        {t("addChild")}
      </OutlinedButton>
    </Box>
  );
}

function ThisUserItem(props: {
  user: UserLightWithoutWishlist;
  onClick: () => void;
}) {
  const { t, i18n } = useTranslation();
  const time = new Intl.DateTimeFormat(i18n.language, {
    month: "long",
    day: "2-digit",
  }).format(new Date(props.user.birthdayDate));

  const auth = useContext(AuthContext);
  const daysBeforeBirthday = daysBeforeNextBirthday(props.user) ?? 4000;
  const theme = useTheme();
  let colorDays: string;

  switch (true) {
    case daysBeforeBirthday == null:
      colorDays = "#ffffff";
      break;
    case daysBeforeBirthday <= 30:
      colorDays = "#845ac0";
      break;
    case daysBeforeBirthday <= 15:
      colorDays = "#ED7F10";
      break;
    case daysBeforeBirthday === 0:
      colorDays = "#ff0000";
      break;
    default:
      colorDays = "#ffffff";
      break;
  }

  return (
    <div>
      <ButtonBase
        sx={{
          width: "100%",
          aspectRatio: "3 / 4",
          transition: "all 0.3s",

          "&:hover": {
            scale: "1.1",
            zIndex: 120,
            borderRadius: "16px",
          },
        }}
        onClick={() => {
          props.onClick();
        }}
      >
        <Box
          sx={{
            textTransform: "none",
            alignItems: "center",
            padding: "4px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            bgcolor: "#ffffff",
            borderRadius: "16px",
            clipPath: "border-box",
            border: "solid 1px",
            borderColor: "primary.light",
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: "100%",
              padding: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {daysBeforeBirthday <= 30 ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  padding: "6px",
                  borderRadius: "12px",
                  bgcolor: "#ffffff",
                  zIndex: 1532,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CakeOutlined
                  sx={{
                    fill: colorDays,
                  }}
                />
                <Typography fontSize={12} color={colorDays} fontWeight={"bold"}>
                  {t("xDays", { count: daysBeforeBirthday })}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            <Box sx={{ aspectRatio: "2/2" }}>
              <AvatarPicture
                alt={props.user.username}
                url={props.user.photoUrl}
              />
            </Box>

            <Box>
              <Typography fontSize={16} fontWeight={"bold"} color={"primary"}>
                {props.user.username}
              </Typography>
              {(props.user.birthdayDate ?? "").length !== 0 ? (
                <Typography fontSize={14} fontStyle={"italic"}>
                  {t("birthdayOn", { value: time })}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    </div>
  );
}

function GestionUser() {
  var auth = useContext(AuthContext);
  var [askDeleteUser, setAskDeleteUser] = useState(false);
  var [deletionInProgress, setDeletionInProgress] = useState(false);
  const { t } = useTranslation();

  function deleteUser() {
    setAskDeleteUser(false);
    setDeletionInProgress(true);
    var token = auth?.token ?? "";
    apiFcmTokenClient
      .deleteToken(token, getFcmToken() ?? "")
      .then(() => {
        apiClient
          .deleteUser(token)
          .then(() => {
            enqueueSnackbar(t("successfullyDeletedAccount"), {
              variant: "success",
            });
            setDeletionInProgress(false);
            auth?.logoutAndGoHome();
          })
          .catch((error) => {
            setDeletionInProgress(false);
            enqueueSnackbar(t("errorDeletingAccount"), { variant: "error" });
          });
      })
      .catch((error) => {
        setDeletionInProgress(false);
        enqueueSnackbar(t("errorDeletingAccount"), { variant: "error" });
      });
  }

  return (
    <Box
      sx={{}}
      marginTop={"16px"}
      marginLeft={0}
      marginRight={0}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Dialog PaperProps={MyPaperPropsDialog} open={askDeleteUser}>
        <img
          key={"Family Gift"}
          src="/assets/family_gift_logo_simple.svg"
          alt={"Family Gift"}
          loading="eager"
          draggable="false"
          style={{
            margin: "auto",
            display: "inline-block",
            width: "80px",
            height: "80px",
          }}
        />
        <DialogTitle id="responsive-dialog-title">
          <Typography
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {t("deleteYourAccount")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              textAlign={"center"}
              color={"primary"}
              fontWeight={"bold"}
            >
              {t("deleteAccountExplain")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            variant="outlined"
            autoFocus
            onClick={() => {
              setAskDeleteUser(false);
            }}
          >
            {t("no")}
          </OutlinedButton>
          <PrimaryErrorButton
            color="primary"
            variant="outlined"
            onClick={() => {
              setAskDeleteUser(false);
              deleteUser();
            }}
            autoFocus
          >
            {t("yes")}
          </PrimaryErrorButton>
        </DialogActions>
      </Dialog>
      <MyWaitingDialog
        open={deletionInProgress}
        message={t("deletingYourAccount")}
      />
      <Typography
        fontWeight={"bold"}
        paddingBottom={"16px"}
        marginTop={"16px"}
        color={"primary"}
      >
        {t("accountManagement")}
      </Typography>
      <OutlinedButton
        endIcon={<Logout />}
        sx={{ width: "100%" }}
        onClick={() => {
          apiFcmTokenClient.deleteToken(auth?.token ?? "", getFcmToken() ?? "");
          auth?.logoutAndGoHome();
        }}
      >
        {t("logout")}
      </OutlinedButton>
      <OutlinedButton
        endIcon={<DeleteForeverOutlined />}
        sx={{ width: "100%" }}
        color="error"
        onClick={() => {
          setAskDeleteUser(true);
        }}
      >
        {t("deleteMyAccount")}
      </OutlinedButton>
    </Box>
  );
}
export default MyProfilePage;
