import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiClient } from "../../../../api/api";
import { AuthContext } from "../../../../api/AuthProvider";
import { useQuery } from "react-query";
import {
  Box,
  ButtonBase,
  Grid,
  Button,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { House, JoinHouseDtp } from "../../../../models/House";
import {
  UserLightWithoutWishlist,
  daysBeforeNextBirthday,
} from "../../../../models/User";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import { CakeOutlined, Check } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { Gift } from "../../../../models/Gift";
import MyNetworkImage from "../../../ReusableComponents/MyNetworkImage";
import MyWaitingDialog, { MyWaitingAnimation } from "../../../ReusableComponents/MyWaitingDialog";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function JoinHouseholdPage() {
  const {t} = useTranslation();
  document.title = t("pageTitle");
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  let navigate = useNavigate();
  let { houseCode } = useParams();
  let auth = useContext(AuthContext);
  let [giftSelected, setGiftSelected] = useState<number[]>(
    auth?.user?.wishList?.map((item) => item.id) ?? []
  );
  let [childSelected, setChildSelected] = useState<number[]>(
    auth?.user?.children?.map((item) => item.id) ?? []
  );

  const [joinHouseProgress, setJoinHouseProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar();


  const { data, error, isLoading } = useQuery({
    queryKey: ["houseCode", houseCode],
    queryFn: () => apiClient.getHouseByCode(auth?.token ?? "", houseCode),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    var me = auth?.user;
    if(me && data){
      var usersId = data?.users.map((item, id) => item.id as number);
      var amIInThisHouse = usersId?.includes((auth?.user?.id as number) ?? -1) ?? false
      if(amIInThisHouse){
        enqueueSnackbar(t("alreadyInHousehold"), {
          variant: "success",
        })
        navigate(`/app/households/${data.id}`, {
          replace:true,
        });
      }
    }
  }, [data])

  return isLoading ? (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} overflow={"visible"} marginTop={"32px"}>
      <MyWaitingAnimation />
      <Typography textAlign={"center"}>{t("loading")}</Typography>
    </Box>
  ) : data ? (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        maxWidth: "1600px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MyWaitingDialog open={joinHouseProgress} message={t("joiningHouseholdInProgress")}/>
      <Typography
        marginTop={"0px"}
        fontWeight={"bold"}
        fontSize={24}
        color={"primary"}
        textAlign={"center"}
      >
                {t("joinHousehold", { houseName: data?.name })}

      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
          }}
        >
          <HouseImage house={data} />
          <HouseMember house={data} />
        </Box>
        <Box
          sx={{
            bgcolor:"primary.light",
            marginTop:"16px",
            borderRadius:"24px",
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box width={isMobile ? "100%" : "50%"} margin={"8px"} >
            <Typography
              marginTop={"24px"}
              fontWeight={"bold"}
              color={"primary"}
              textAlign={"center"}
            >
              {t("selectIdeas")}
              </Typography>
            <Typography textAlign={"center"}>
            {t("selectIdeasInfo")}

            </Typography>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"flex-start"}
              sx={{ margin: "auto", transition: "all 0.3s" }}
            >
              {auth?.user?.wishList.map((item, i) => (
                <Grid
                  item
                  xs={4}
                  sm={3}
                  md={4}
                  lg={3}
                  xl={3}
                  padding={1}
                  key={item.id}
                >
                  <SelectableItem
                    selected={
                      giftSelected?.find((itemId) => item?.id === itemId) !=
                      null
                    }
                    onClick={(event) => {
                      var selected =
                        giftSelected?.find((itemId) => item?.id === itemId) !=
                        null;
                      if (selected)
                        setGiftSelected(
                          giftSelected?.filter((itemId) => itemId !== item.id)
                        );
                      else setGiftSelected(giftSelected?.concat(item.id));
                    }}
                  >
                    <GiftItem key={item.id} gift={item} />
                  </SelectableItem>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box width={isMobile ? "100%" : "50%"} sx={{
            margin:"8px",
            borderRadius:"16px"
          }}>
            <Typography
              marginTop={"24px"}
              fontWeight={"bold"}
              color={"primary"}
              textAlign={"center"}
            >
              {t("selectChildren")}
              </Typography>
            <Typography textAlign={"center"}>
            {t("selectChildrenInfo")}

            </Typography>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"flex-start"}
              sx={{ margin: "auto", transition: "all 0.3s" }}
            >
              {auth?.user?.children
                .sort(function (a, b) {
                  return (
                    (daysBeforeNextBirthday(a) ?? 0) -
                    (daysBeforeNextBirthday(b) ?? 0)
                  );
                })
                .map((item, i) => (
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={4}
                    lg={3}
                    xl={3}
                    padding={1}
                    key={item.id}
                  >
                    <SelectableItem
                      selected={
                        childSelected?.find((itemId) => item?.id === itemId) !=
                        null
                      }
                      onClick={(event) => {
                        var selected =
                          childSelected?.find(
                            (itemId) => item?.id === itemId
                          ) != null;
                        if (selected)
                          setChildSelected(
                            childSelected?.filter(
                              (itemId) => itemId !== item.id
                            )
                          );
                        else setChildSelected(childSelected?.concat(item.id));
                      }}
                    >
                      <UserLightItem key={item.id} user={item} />
                    </SelectableItem>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "auto",
        }}
        display={"flex"}
        alignItems={"center"}
        justifyItems={"center"}
        flexDirection={"row"}
      >
        <Button
          sx={{
            position: "relative",
            width: "auto",
            paddingLeft: "32px",
            paddingRight: "32px",
            margin: "8px",
            borderRadius: "50px",
            backgroundColor: "white",
            textTransform: "none",
            fontSize: 16,
            border: "2px solid",
            fontWeight: "600",
            "&:hover": {
              backgroundColor: "primary.light",
              border: "2px solid",
            },
          }}
          onClick={() => {
            navigate(`/app/households`, {
              replace:true,
            });
          }}
        >
          {t("cancel")}
          </Button>
        <Button
          sx={{
            position: "relative",
            width: "auto",
            paddingLeft: "32px",
            paddingRight: "32px",
            margin: "8px",
            borderRadius: "50px",
            backgroundColor: "white",
            textTransform: "none",
            fontSize: 16,
            border: "2px solid",
            fontWeight: "600",
            "&:hover": {
              backgroundColor: "primary.light",
              border: "2px solid",
            },
          }}
          onClick={() => {
            const joinHouseDto = new JoinHouseDtp(childSelected, giftSelected);
            const token = auth?.token ??"";
            const code = data.code;
            apiClient.joinHousehold(joinHouseDto, token, code).then((response) => {
              auth?.refreshUser();
              enqueueSnackbar(t("joinHouseholdSuccess"), {
                variant: "success",
              })
              navigate(`/app/households/${data.id}`, {
                replace:true,
              });
            }).catch((error) => {
              enqueueSnackbar(t("joinHouseholdError", { status: error.response?.status }), {
                variant: "error",
              });
            })
          }}
        >
          {t("joinHouseholdButton")}
          </Button>
      </Box>
    </Box>
  ) : (
    <Typography textAlign={"center"}>
      {t("householdNotFound")}
    </Typography>
  );
}

function HouseImage(props: { house: House }) {
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "16px",
        marginLeft: "auto",
        marginRight: "auto",
        flexGrow: 1,
        width: isMobile ? "100%" : "50%",
        aspectRatio: "16/6",
        maxHeight: isMobile ? "250px" : "none",
      }}
    >
      <MyNetworkImage
        url={props.house?.pictureUrl}
        alt={props.house?.name ?? ""}
        cover={true}
      />
    </Box>
  );
}

function HouseMember(props: { house: House }) {
  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  const {t} = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        justifyItems: "center",
        display: "flex",
        flexGrow: 1,
        width: isMobile ? "100%" : "50%",
        flexDirection: "column",
        marginTop: "16px",
      }}
    >
      <Typography
        textAlign={"center"}
        color={"primary"}
        fontSize={20}
        fontWeight={"bold"}
      >
        {t("houseMember", {count: props.house.users.length})}
      </Typography>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"flex-start"}
        sx={{ margin: "auto", transition: "all 0.3s" }}
      >
        {props.house.users
          .sort(function (a, b) {
            return (
              (daysBeforeNextBirthday(a) ?? 0) -
              (daysBeforeNextBirthday(b) ?? 0)
            );
          })
          .map((item, i) => (
            <Grid
              item
              xs={4}
              sm={3}
              md={4}
              lg={3}
              xl={3}
              padding={1}
              key={item.id}
            >
              <UserLightItem key={item.id} user={item} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export function UserLightItem(props: { user: UserLightWithoutWishlist }) {
  return (
    <Box
      sx={{
        textTransform: "none",
        alignItems: "center",
        justifyItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        aspectRatio: "4/5",
        background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
        borderRadius: "16px",
        clipPath: "border-box",
        border: "solid 1px",
        borderColor: "primary.light",
      }}
    >
      <Box
        sx={{
          textTransform: "none",
          alignItems: "center",
          justifyItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
          borderRadius: "16px",
          clipPath: "border-box",
          border: "solid 1px",
          borderColor: "primary.light",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "100%",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Box width={"80px"} height={"80px"}>
            <AvatarPicture
              alt={props.user.username}
              url={props.user.photoUrl}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
          >
            {props.user.username}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function GiftItem(props: { gift: Gift }) {
  return (
    <Box
      sx={{
        textTransform: "none",
        alignItems: "center",
        justifyItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        aspectRatio: "3/4",
        overflow: "hidden",
        background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
        borderRadius: "16px",
        clipPath: "border-box",
        border: "solid 1px",
        borderColor: "primary.light",
      }}
    >
      <Box
        sx={{
          textTransform: "none",
          alignItems: "center",
          justifyItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
          borderRadius: "16px",
          clipPath: "border-box",
          border: "solid 1px",
          borderColor: "primary.light",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "100%",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Box width={"100%"} height={"80px"} flexGrow={1}>
            <MyNetworkImage
              alt={props.gift.name}
              url={props.gift.photoUrl}
              cover={true}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"center"}
            textOverflow={"ellipsis"}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {props.gift.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function SelectableItem(props: {
  selected: boolean;
  onClick: React.MouseEventHandler;
  children: React.ReactNode;
}) {
  return (
    <ButtonBase
      sx={{
        textTransform: "none",
        alignItems: "center",
        justifyItems: "center",
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "space-between",
        opacity: props.selected ? "1" : "0.6",
        filter: props.selected ? "none" : "grayscale(100%)",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        //background: "linear-gradient(75deg, #E1D5FF, #ffffff 30%,#FFDFEFFF)",
        borderRadius: "16px",
        clipPath: "border-box",
        //border: "solid 1px",
        //borderColor: "primary.light",
        transition: "all 0.3s",
        "&:hover": {
          scale: "1.2",
          zIndex: 120,
        },
      }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          borderRadius: "150px",
          padding: "4px 5px 2px 5px",
          boxSizing: "border-box",
          border: "2px solid",
          borderColor: "primary.main",
          position: "absolute",
          bgcolor: "#ffffff",
          zIndex: "130",
          right: 8,
          top: 4,
          alignItems: "center",
          opacity: props.selected ? "100%" : "0%",
          transition: "all 0.3s",
        }}
      >
        <Check
          sx={{ marginTop: "0", marginBottom: "0" }}
          fontSize={"medium"}
          color="primary"
        />
      </Box>
      {props.children}
    </ButtonBase>
  );
}

export default JoinHouseholdPage;
