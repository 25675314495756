import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import frJSON from './locales/fr.json';
import enJSON from './locales/en.json';
import esJSON from './locales/es.json';
import itJSON from './locales/it.json';
import deJSON from './locales/it.json';
import nlJSON from './locales/it.json';
import ptJSON from './locales/it.json';

import LanguageDetector from 'i18next-browser-languagedetector';


i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
 resources: {
    en: {...enJSON},
    fr: {...frJSON},
    es: {...esJSON},
    it: {...itJSON},
    pt: {...ptJSON},
    de: {...deJSON},
    nl: {...nlJSON}
 },
 load: 'currentOnly',
 detection: {order: ['navigator']},
 debug: false,
 whitelist: ['en', 'fr', 'es', 'it', 'nl', 'pt', 'de'],

 //lng: "en", 
 fallbackLng: "fr",
 interpolation: {
    escapeValue: false,
 },
 
});

export default i18n;