import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { MotionConfig, motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";

export function MyWaitingAnimation() {
  return <motion.img
        animate={{
          y: ["15px", "-25px", "15px"],
          scaleY: [0.8, 1.2, 0.8],
          scaleX: [1.2, 0.5, 1.2],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: ["easeOut", "easeIn"],
        }}
        key={"Family Gift"}
        src="/assets/family_gift_logo_simple.svg"
        alt={"Family Gift"}
        loading="eager"
        draggable="false"
        style={{
          margin: "auto",
          display: "inline-block",
          width: "80px",
          height: "80px",
        }}
      />
}

function MyWaitingDialog(props: { open: boolean; message: string }) {
  const {t} = useTranslation();
  return (
    <Dialog
      PaperProps={MyPaperPropsDialog}
      open={props.open}
    >
      <MyWaitingAnimation />
      <DialogTitle
        id="alert-dialog-title"
        textAlign={"center"}
        color={"primary"}
        fontWeight={"bold"}
        paddingTop={0}
      >
        {t("pleaseWait")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          textAlign={"center"}

          paddingTop={0}
        >
          {props.message}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export const MyPaperPropsDialog = {
  sx: {
    padding: "32px 8px 0px 8px",
    bgcolor: "primary.light",
    borderRadius: "16px",
    overflow: "visible",
  },
};

export default MyWaitingDialog;
