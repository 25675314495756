import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../api/AuthProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { apiClient } from "../../../../api/api";
import AvatarPicture from "../../../ReusableComponents/AvatarPicture";
import ExternalGiftItem from "../../../ReusableComponents/ExternalGiftItem";
import { IconGift } from "../../WebAppNavBar/WebAppNavBar";
import {
  daysBeforeNextBirthday,
  getBirthdayDate,
} from "../../../../models/User";
import MyFab from "../../../ReusableComponents/MyFab";
import SortOrderMenu from "../../../ReusableComponents/SortOrderMenu";
import { SettingsContext, SortOrder } from "../../../../utils/SettingsProvider";
import {
  Gift,
  giftComparatorAlpha,
  giftComparatorOldToRecent,
  giftComparatorPrice,
  giftComparatorRate,
  giftComparatorRecentToOld,
} from "../../../../models/Gift";
import CarouselTutorial from "../../../ReusableComponents/CarouselTutorial";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import ErrorWidget from "../../../ReusableComponents/ErrorWidget";

function ExternalUserPageList() {
  let { userId } = useParams();

  return (
    <>
      {userId ? (
        <ActualExternalList userId={userId} showHeader={true} />
      ) : (
        <></>
      )}
    </>
  );
}

export function ActualExternalList(props: {
  userId: string;
  showHeader: boolean;
}) {

  const isMobile = useMediaQuery(
    createTheme(useTheme()).breakpoints.down("md")
  );
  var auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  var settings = useContext(SettingsContext);

  const [list, setList] = useState<Gift[] | undefined>(undefined);
  const [otherUserlist, setOtherUserList] = useState<Gift[] | undefined>(
    undefined
  );
  const [errorCode, setErrorCode] = useState(200);

  function getErrorMessage(errorCode: number): string {
    switch(errorCode){
      case 404: return t("userNotFound"); 
      case 401: return t("errorAuthenticating"); 
      case 403: return t("notRelativesError"); 
      default: return t("unknownError"); 
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ["user", props.userId],
    queryFn: () => apiClient.getUserById(auth?.token ?? "", props.userId).catch((error: AxiosError) => {
      setErrorCode(error.response?.status??200)
    }),
    refetchOnWindowFocus: true,
  });


  useEffect(() => {
    switch (settings?.sortOrder) {
      case SortOrder.ALPHABETICALLY:
        setList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() == props.userId
            ) ?? []
          ).sort(giftComparatorAlpha),
        ]);
        setOtherUserList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() != props.userId
            ) ?? []
          ).sort(giftComparatorAlpha),
        ]);
        break;
      case SortOrder.PRICE:
        setList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() == props.userId
            ) ?? []
          ).sort(giftComparatorPrice),
        ]);
        setOtherUserList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() != props.userId
            ) ?? []
          ).sort(giftComparatorPrice),
        ]);
        break;
      case SortOrder.RATE:
        setList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() == props.userId
            ) ?? []
          ).sort(giftComparatorRate),
        ]);
        setOtherUserList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() != props.userId
            ) ?? []
          ).sort(giftComparatorRate),
        ]);
        break;
      case SortOrder.OLD_TO_RECENT:
        setList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() == props.userId
            ) ?? []
          ).sort(giftComparatorOldToRecent),
        ]);
        setOtherUserList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() != props.userId
            ) ?? []
          ).sort(giftComparatorOldToRecent),
        ]);
        break;
      case SortOrder.RECENT_TO_OLD:
        setList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() == props.userId
            ) ?? []
          ).sort(giftComparatorRecentToOld),
        ]);
        setOtherUserList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() != props.userId
            ) ?? []
          ).sort(giftComparatorRecentToOld),
        ]);
        break;
      default:
        setList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() == props.userId
            ) ?? []
          ).sort(giftComparatorOldToRecent),
        ]);
        setOtherUserList([
          ...(
            data?.wishList.filter(
              (item) => item.addedBy.id.toString() != props.userId
            ) ?? []
          ).sort(giftComparatorOldToRecent),
        ]);
        break;
    }
    if(data){
      document.title = t("xList", { value: data?.username });
    } else {
      document.title = "Family Gift";

    }
  }, [settings?.sortOrder, data]);


  useEffect(() => {
    if (auth?.user?.id === parseInt(props.userId)) {
      return navigate("/app/myList");
    }
  });

  var carouselItemsEmptyList = [
    {
      title: t("xListIsVeryEmpty", { value: data?.username }),
      subtitle: t("youCanAddIdeasOnXList", { value: data?.username }),
      icon: "../../assets/family_gift_logo_simple.svg",
    },
    {
      title: t("weKeepTheSurprise"),
      subtitle: t("xWontSeeIdeas", { value: data?.username }),
      icon: "../../assets/incognito.svg",
    },
  ];

  return (
    <Box
      sx={{
        maxWidth: "1400px",
        width: "auto",
        alignItems: "center",
        display: "flex",
        paddingBottom: "75px",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {isLoading ? <Typography>{t("loading")}</Typography> : data !== undefined ? (
        <Box width={"100%"} position={"relative"}>
          <MyFab
            onClick={() => {
              navigate(`/app/gift/add`, { state: { user: data } });
            }}
            Icon={IconGift}
            text={t("addIdeaOnXList",{value: data.username})}
          />

          {props.showHeader ? (
            <Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                }}
              >
                <SortOrderMenu large={false} />
              </Box>
              <Box
                sx={{
                  marginTop: "8px",
                  maxWidth: "500px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "8px 24px 8px 24px",
                  borderRadius: "16px",
                  background:
                    "linear-gradient(24deg, #E1D5FF, #ffffff 45%,#FFDFEFFF)",
                }}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Box height={"75px"} width={"75px"}>
                  <AvatarPicture
                    url={data?.photoUrl}
                    alt={data?.username as string}
                  />
                </Box>
                <Box
                  padding={"8px"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"left"}
                >
                  <Typography
                    fontSize={18}
                    fontWeight={"bold"}
                    color={"primary"}
                  >
                    {t("xList", {value: data?.username})}
                  </Typography>
                  <Typography fontSize={16} fontWeight={"bold"}>
                    {t("xGift",{count:data?.wishList.length})}
                  </Typography>
                  {data.birthdayDate ? (
                    <Typography fontSize={16}>
                      {t("birthdayOn", {value: getBirthdayDate(data)}) + ", " + t("inXDays",{count: daysBeforeNextBirthday(data)??300})}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Divider
                sx={{
                  marginTop: "16px",
                  width: "80%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Box>
          ) : (
            <></>
          )}

          {list == undefined || otherUserlist == undefined ? (
            <></>
          ) : list.length != 0 || otherUserlist.length != 0 ? (
            <>
              {list.length > 0 ? (
                <Typography
                  marginTop={2}
                  marginLeft={2}
                  fontSize={16}
                  fontWeight={"bold"}
                  color={"primary"}
                >
                  {t("ideasAddedByX", {value: data.username})}
                </Typography>
              ) : (
                <></>
              )}
              <Grid marginTop={1} container>
                {list.map((item, _) => (
                  <ExternalGiftItem key={item.id} gift={item} />
                ))}
              </Grid>
              {otherUserlist.length > 0 && list.length > 0 ? (
                <Divider sx={{ margin: "18px" }}></Divider>
              ) : (
                <></>
              )}
              {otherUserlist.length > 0 ? (
                <Typography
                  marginTop={2}
                  marginLeft={2}
                  fontSize={16}
                  fontWeight={"bold"}
                  color={"primary"}
                >
                  {t("ideasAddedByXRelatives", {value: data.username})}
                  </Typography>
              ) : (
                <></>
              )}
              <Grid marginTop={1} container>
                {otherUserlist.map((item, _) => (
                  <ExternalGiftItem key={item.id} gift={item} />
                ))}
              </Grid>
            </>
          ) : (
            <CarouselTutorial items={carouselItemsEmptyList} />
          )}
        </Box>
      ) : errorCode !== 200 ? <ErrorWidget errorCode={errorCode} errorMessage={getErrorMessage(errorCode)}/>: (
        <></>
      )}
    </Box>
  );
}

export default ExternalUserPageList;
